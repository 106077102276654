<template>
    <div class="action-result">
        <div class="action-result-status" :class="[status]"><span>{{ status == 'error' ? 'Ошибка' : 'Успешно' }}</span></div>
        <ul class="action-result-errors" v-if="errors && errors.length">
            <li v-for="error in errors">{{ error.message }}</li>
        </ul>
        <div class="action-result-info" v-if="resultData.length">
            <div v-for="item in resultData"><span style="font-weight: 500;">{{item.name}}</span>: {{ item.value }}</div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ActionsResult",
    props:['data', 'action'],
    computed:{
        status(){
            return this.data?.status == 'error' ? 'error' : 'success';
        },
        errors(){
            if(!Array.isArray(this.data.errors) && !this.data.errors.length) return false;
            return this.data.errors
        },
        resultData(){
            if(this.action.action != 'renewProfile') return false;
            const data = this.data?.data;
            const status = data?.status ? data?.status['Заголовок'] : null;
            const bonuceBefore = data?.bonuce ? data?.bonuce['Баланс до'] : null;
            const bonuceAfter = data?.bonuce ? data?.bonuce['Баланс после'] : null;
            let result = [];
            if(status) result.push({ name:'Установлен статус', value: status })
            if(bonuceBefore) result.push({ name:'Баланс бонусов до', value: bonuceBefore })
            if(bonuceAfter) result.push({ name:'Новое значение бонусов', value: bonuceAfter })
            return result;
        }
    }
}
</script>

<style lang="scss">
.action-result{

    width: 100%;
    padding: 1rem;
    line-height: 1;
    margin-top: 1rem;
    box-sizing: border-box;
    border: 1px solid #ddd;
    
    .action-result-status{

        display: block;
        font-size: 16px;
        margin-bottom: 10px;
        
        span{
            font-weight: 500;
        }
        &.error{
            span{
                color: red;
            }
        }
        &.success{
            span{
                color: green;
            }
        }

    }

    .action-result-errors{
        padding: 0 0 1rem 1rem;
        font-size: 15px;
        color: red;
    }

    .action-result-info{
        display: block;
        margin-top: 10px;
        line-height: 1.5;;
        div{

        }
    }
}
</style>