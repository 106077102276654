<template>
    <transition name="fade">
        <div class="modal" v-if="state.isOpen" >
            <div class="modal__overlay"  @click="close"></div>
            <div class="modal__container">
                <div class="modal__content">
                    <div class="modal__close" @click="close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                    </div>
                    <div class="modal__header" v-if="data && data.header && data.header.title">
                        <div class="modal__title">{{data.header.title}}</div>

                    </div>
                    <div class="modal__body scrollbar">
                        <div class="modal__slot"><slot></slot></div>
                    </div>
                    <div class="modal__footer" v-if="data && data.footer && data.footer.buttons">
                        <button class="btn"
                                :class="button.classList"
                                @click="click(button.eventName)" :key="k" v-for="(button, k) in data.footer.buttons"
                        ><span>{{button.text}}</span></button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>


export default {
    props:['name', 'data'],
    data(){
        return {
            state:{
                isOpen:false
            },
            default:{
                config:{
                    hasPattern:false,
                }
            }
        }
    },
    computed:{
        modalName(){
            return this.name+'Modal';
        }
    },
    methods:{
        open(){
            this.state.isOpen = true;
         //   scroll.disable();
        },
        close(){
            this.state.isOpen = false;
         //   scroll.enable();
        },
        click(eName){
            this.$emit(eName, this);
            this.$root.$emit(this.modalName + '::' + eName, this)
        },
        update(data){
            this.data = data;
        }
    },
    mounted() {
        this.$root.$on(this.modalName + '::open', data => {
            this.open()
        })
        this.$root.$on(this.modalName + '::close', data => {
            this.close()
        })

        this.$on('update', data => {
            this.update(data)
        })

        this.$on('open', data => {
            this.open()
        })
        this.$on('close', data => {
            this.close()
        })

    }
}
</script>

<style lang="scss">
.modal{

    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    z-index: 1000;


    &__slot{
        padding: 0 10px;
    }


    &.--has-pattern{

        .modal__content{

            padding-left: calc(4em + 60px);
            position: relative;
            overflow: hidden;

            &:before{
                content: '';
                width: 60px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                background: linear-gradient(45deg, var(--color-blue), var(--color-blue-200));
            }

        }
    }

    &__overlay{
        background: #0006;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &__header{
        margin-bottom: 1rem;
        display: block;
        position: relative;
    }
    &__title{
        line-height: 1em;
        font-weight: 600;
        font-size: 22px;
        display: block;
    }
    &__close{
        position: absolute;
        right: 1rem;
        top: 22px;

    }


    &__container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
		padding: 0 1rem;
        position: relative;
        z-index: 6;

		@media (max-width:460px){
			padding: 0;
			height: 100%;
		}
    }

    &__content{
        /*min-width: 500px;*/
        min-height: 300px;
        background: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        position: relative;
        box-shadow: 0px 0px 10px 0px #00000047;
        max-height: 100%;
        padding: 4rem 3rem;
        overflow: auto;
		@media (max-width:768px){
			padding: 3rem 2rem;
		}
		@media (max-width:460px){
			padding: 2rem;
			height: 100%;
			border-radius: 0px;
		}
    }

    &__body{
        display: block;
        margin-bottom: 1rem;
        max-height: 100%;
        min-height: 150px;
        overflow: auto;

		@media (max-width:460px){
			height: 100%;
			max-height: 100%;
		}
    }
    &__footer{
        display: flex;
        gap: 5px;
        align-items: flex-end;
        width: 100%;
        justify-content: flex-end;
    }
}
</style>
