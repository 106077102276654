<template>
	<div class="messages-wrapper">
		<div class="messages" v-if="dialog">

			<Modal name="UserInfo">
				<UserInfo :userId="dialog?.userId"></UserInfo>
			</Modal>
		
			<div class="messages-header" >
				<div class="messages-header-wrap">

					<div class="messages-header-toggle" v-if="false">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path id="vac-icon-toggle" d="M5,13L9,17L7.6,18.42L1.18,12L7.6,5.58L9,7L5,11H21V13H5M21,6V8H11V6H21M21,16V18H11V16H21Z"></path><!----></svg>
					</div>

					<div class="messages-header-title">
						<div class="text-ellipsis">
							<div class="messages-header-title-name text-ellipsis">
								{{ dialog.user.name }} {{ dialog.user.lastName }}
							</div>
							<div style="font-size: 14px">
								{{dialog.title}}
							</div>
							<div class="messages-header-title-info text-ellipsis">{{ dialog.user.login }}</div>
						</div>
					</div>

					<div class="messages-header-controlls">
						<UiDrop>
							<UiDropTrigger style="cursor: pointer">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path id="vac-icon-menu" d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"></path><!----></svg>
							</UiDropTrigger>
							<UiDropContent>
								<UiDropContentList>

									<UiDropContentButton  @click="openUserInfo()" >О пользователе</UiDropContentButton>
									<UiDropContentButton v-if="!dialog.isClosed" @click="closeDialog()" >Закрыть чат</UiDropContentButton>
									<UiDropContentButton v-if="dialog.isClosed" @click="openDialog()" >Возобновить чат</UiDropContentButton>
								</UiDropContentList>
							</UiDropContent>
						</UiDrop>

					</div>
				</div>
			</div>

			<div class="messages-notice" :class="{'--visible':state.isPagenLoading}">
				<span>Загрузка</span>
			</div>

			<div class="messages-list" ref="roomMessages" @scroll="onMessagesScroll">

				<div class="messages-list-items">
					<div v-for="date in itemsByDates">
						<div class="messages-message-date"><span>{{date.date}}</span></div>

					
						<div class="messages-message-box"
						     v-for="message in date.items"
						     :class="{'messages-message-box-current':message.isMy}"
						>
							<div class="messages-message-container">

					
								<div class="messages-message-card"  :class="{'--my':message.isMy, '--is-system':message.isSystem}">
									<div class="messages-message-card-author">
										<span >{{message.userName ? message.userName : 'Оператор'}}</span>
									</div>
									<div class="messages-message-card-text" v-if="!message.isProduct">
										{{message.message}}
									</div>
									<div class="messages-message-card-text" v-if="message.isProduct">
										<MessageProduct :data="message.json.product"></MessageProduct>
									</div>
									<div class="messages-message-card-timestamp">
										<span>{{$helpers.formatTime(message.timestamp)}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="messages-footer">
				<div class="messages-footer-container">
					<textarea
						ref="roomTextarea"
						:style="{
							'min-height': `20px`,
							'padding-left': `14px`
						}"
						placeholder="Начните писать текст"
						class="messages-footer-textarea"
						:disabled="state.isChatLoading"
						@input="onInputChange"
						@keydown.enter.exact.prevent="send()"
					></textarea>
					<div class="messages-footer-controlls">
						<div class="messages-footer-controlls-btn" @click="send()">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path id="vac-icon-send" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path><!----></svg>
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="messages-empty" v-if="!dialog">
			<div>
				<div class="messages-empty-container">
					<div class="messages-empty-icon">
						<svg xmlns="http://www.w3.org/2000/svg"
						     width="42px" height="32px" viewBox="0 0 42 32"   xml:space="preserve">
<g>
	<path fill="#828282" d="M35.652,14.023c-0.264-0.082-0.544,0.062-0.628,0.325c-0.083,0.263,0.062,0.544,0.325,0.628
		C39.257,16.221,41,18.078,41,21c0,2.599-2.371,4.616-3.783,5.588C37.081,26.682,37,26.835,37,27v3.823
		c-0.73-0.272-2.003-0.89-3.126-2.155c-0.117-0.131-0.295-0.191-0.468-0.159c-0.285,0.055-0.576,0.133-0.871,0.212
		C32.025,28.858,31.499,29,31,29c-2.568,0-4.366-0.552-6.204-1.903c-0.224-0.164-0.535-0.115-0.699,0.107
		c-0.164,0.223-0.116,0.535,0.106,0.699C26.23,29.393,28.199,30,31,30c0.631,0,1.223-0.159,1.795-0.313
		c0.178-0.049,0.355-0.097,0.53-0.138c1.869,1.974,3.983,2.423,4.075,2.441c0.033,0.007,0.066,0.01,0.1,0.01
		c0.114,0,0.227-0.039,0.316-0.113C37.933,31.792,38,31.65,38,31.5v-4.239c2.582-1.841,4-4.057,4-6.261
		C42,17.619,39.983,15.402,35.652,14.023z"/>
	<path fill="#828282" d="M33,13.5C33,5.804,25.982,0,16.677,0C7.325,0,0,5.931,0,13.502c0,4.539,3.211,7.791,6,9.759v6.636
		c0,0.17,0.086,0.327,0.228,0.42c0.083,0.053,0.177,0.08,0.272,0.08c0.069,0,0.139-0.015,0.205-0.044
		c0.146-0.065,3.559-1.616,6.479-4.809c1.265,0.235,2.696,0.461,3.994,0.461C26.641,26.005,33,20.979,33,13.5z M17.177,25.005
		c-1.31,0-2.799-0.251-4.083-0.496c-0.173-0.031-0.351,0.028-0.468,0.159c-2.05,2.312-4.459,3.781-5.626,4.414V23
		c0-0.165-0.081-0.318-0.217-0.412C4.145,20.773,1,17.725,1,13.502C1,6.491,7.886,1,16.677,1C25.413,1,32,6.374,32,13.5
		C32,20.382,26.043,25.005,17.177,25.005z"/>
	<path fill="#828282" d="M16.5,11.5c-1.103,0-2,0.897-2,2s0.897,2,2,2s2-0.897,2-2S17.603,11.5,16.5,11.5z M16.5,14.5
		c-0.551,0-1-0.448-1-1s0.449-1,1-1s1,0.448,1,1S17.051,14.5,16.5,14.5z"/>
	<path fill="#828282" d="M23.5,11.5c-1.103,0-2,0.897-2,2s0.897,2,2,2s2-0.897,2-2S24.603,11.5,23.5,11.5z M23.5,14.5
		c-0.551,0-1-0.448-1-1s0.449-1,1-1s1,0.448,1,1S24.051,14.5,23.5,14.5z"/>
	<path fill="#828282" d="M9.5,11.595c-1.103,0-2,0.897-2,2s0.897,2,2,2s2-0.897,2-2S10.603,11.595,9.5,11.595z M9.5,14.595
		c-0.551,0-1-0.448-1-1s0.449-1,1-1s1,0.448,1,1S10.051,14.595,9.5,14.595z"/>
</g>
</svg>
					</div>
					<div class="messages-empty-text">Диалог не выбран</div>
				</div>
			</div>

		</div>






	</div>
</template>

<script>

import Modal from "./Modal.vue";
import UserInfo from "./UserInfo.vue";
import UiDrop from "/src/components/Drop";
import UiDropTrigger from "/src/components/Drop/Trigger";
import UiDropContent from "/src/components/Drop/Content/index";
import UiDropContentList from "/src/components/Drop/Content/List";
import UiDropContentButton from "/src/components/Drop/Content/Button";
import MessageProduct from "@/components/MessageProduct";


export default {
	components:{Modal, UserInfo, UiDrop, UiDropTrigger, UiDropContent, UiDropContentList, UiDropContentButton, MessageProduct},
	data(){
		return {
			dialog:null,
			result:null,
			message:'',
			state:{
				isPagenLoading:false,
				isChatLoading:false,
			}
		};
	},
	methods:{
		getTextareaRef() {
			return this.$refs.roomTextarea
		},
		getMessagesListRef() {
			return this.$refs.roomMessages
		},
		openUserInfo(){
			this.$root.$emit('UserInfoModal::open');
		},
		async closeDialog(){
			const isClose = confirm('Вы действительно хотите закрыть диалог?');
			if(!isClose) return false;
			const result = await this.$useFetch('/support/manager/dialog/close', {
				method:'POST',
				body: JSON.stringify({
					dialogId:this.dialog.id,
				})
			})
			this.$root.$emit('drop::closeAll');
		},
		async openDialog(){
			const result = await this.$useFetch('/support/manager/dialog/open', {
				method:'POST',
				body: JSON.stringify({
					dialogId:this.dialog.id,
				})
			})
			this.$root.$emit('drop::closeAll');
		},
		async loadMessages(){
			this.result = await this.$useFetch('/support/manager/dialogs/messages?dialogId='+this.dialog.id)
		},
		async readMessages(){
			await this.$useFetch('/support/manager/dialogs/messages/read?dialogId='+this.dialog.id, {
				method:'POST'
			})
		},
		async loadMessagesPrev(){
			if(!this.isAllowNextPage) return;
			this.state.isPagenLoading = true;
			let params = new URLSearchParams();
			params.set('dialogId', this.dialog.id);
			params.set('page', this.nextPage);
			let result = await this.$useFetch('/support/manager/dialogs/messages?'+params.toString())
			this.result.page = result.page;
			if(Array.isArray(result.items)){
				this.result.items = [...this.result.items, ...result.items]
			}

			this.state.isPagenLoading = false;
		},
		async send(){
			this.state.isChatLoading = true;
			const result = await this.$useFetch('/support/manager/dialogs/messages/send', {
				method:'POST',
				body: JSON.stringify({
					dialogId:this.dialog.id,
					message:this.message.trim(),
				})
			})
			if(result['id']) this.clearTextarea();
			this.state.isChatLoading = false;
		},
		async addMessage(message){
			if(!message) return false;
			const localMessage = this.result.items.find(m => m.id == message.id)
			if(localMessage) return false;
			this.result.items.unshift(message)
			setTimeout(() => this.scrollMessagesList(), 200)
			await this.readMessages()
		},
		onMessagesScroll(event){
			const target = event.target;
			if(target.scrollTop < 10 && !this.state.isPagenLoading){
				this.loadMessagesPrev();
			}
			//console.log(target.scrollTop, target.scrollHeight)
		},
		onInputChange(e){
			if(this.state.isChatLoading) return false;
			if (this.getTextareaRef()?.value || this.getTextareaRef()?.value === '') {
				this.message = this.getTextareaRef()?.value
			}
			//this.keepKeyboardOpen = true
			this.resizeTextarea()
			this.$emit('typing-message', this.message)
			//if(e.nativeEvent.inputType === "insertLineBreak") return;
		},
		resizeTextarea() {
			const el = this.getTextareaRef()

			if (!el) return

			const padding = window
				.getComputedStyle(el, null)
				.getPropertyValue('padding-top')
				.replace('px', '')

			el.style.height = 0
			el.style.height = el.scrollHeight - padding * 2 + 'px'
		},
		clearTextarea(){
			const el = this.getTextareaRef()
			if (!el) return
			el.value = '';
			this.resizeTextarea();
		},
		scrollMessagesList(smooth = false){
			const el = this.getMessagesListRef()
			if (!el) return
			if (smooth){
				el.scrollTo({
					top: el.scrollHeight,
					behavior: "smooth",
				});
			}else{
				el.scrollTop = el.scrollHeight;
			}

		},

	},
	computed:{
		isAllowNextPage(){
			return this.result.pages > this.result.page;
		},
		nextPage(){
			let totalPages = this.result.pages;
			let currentPage = this.result.page;
			if(totalPages > currentPage) currentPage++;
			return currentPage;
		},
		items(){
			if(!this.result || !Array.isArray(this.result?.items)) return false;
			let result = [];
			let messages = JSON.parse(JSON.stringify(this.result?.items))
			messages = messages.map(item  => {
				item.isProduct = item.json?.product?.id;
				item.isSystem = item.json?.isSystem == true;
				item.userName = item.user.name;
				item.isMy = item.user?.isManager || parseInt(item.userId) == this.$root.profile.id;
				return item;
			})
			messages = messages.reverse();
			return messages;
		},
		itemsByDates(){
			let result = [];
			if(!this.items) return false;
			this.items.forEach(item => {
				let date = this.$helpers.formatDateReadble(item.timestamp);
				let resultItem = result.find(i => i.date == date);
				if(resultItem){
					resultItem.items.push(item)
				}else{
					result.push({
						'date':date,
						'items':[item]
					})
				}
			})
			return result;
		}
	},
	mounted(){

		this.$root.$on('setDialog', async dialog => {
			this.dialog = dialog;
			await this.loadMessages()
			this.scrollMessagesList();
			await this.readMessages()
		});



		this.$root.$on('updateState', payload => {
			if(!this.dialog) return false;
			if(payload?.dialog?.id == this.dialog.id) {
				this.addMessage(payload?.message)
			}
		})
	}
}
</script>

<style lang="scss" scoped>
.messages{
	position: relative;
	height: 100%;
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-flow: column;

	.drop{
		--drop-btn-icon-size: 14px;
		--drop-content-min-width: 150px;
	}

	&-notice{
		position: absolute;
		left: 50%;
		top: 76px;
		transform: translateX(-50%) translateY(-200%);
		background: #fff;
		z-index: 9;
		line-height: 1rem;
		padding: 5px 15px;
		font-size: 14px;
		border-radius: 15px;
		box-shadow: 0px 2px 5px 0px #00000024;
		opacity: 0;
		visibility: hidden;
		transition: 200ms;
		&.--visible{
			transform: translateX(-50%) translateY(0);
			visibility: visible;
			opacity: 1;
		}

	}

	&-empty{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		text-align: center;
	}

	&-wrapper{
		display: block;
		width: 100%;
		height: 100%;
	}

	&-header{
		position: absolute;
		display: flex;
		align-items: center;
		height: 64px;
		width: 100%;
		z-index: 10;
		margin-right: 1px;
		background: var(--chat-header-bg-color);
		border-top-right-radius: var(--chat-container-border-radius);
		&-wrap{
			display: flex;
			align-items: center;
			min-width: 0;
			height: 100%;
			width: 100%;
			padding: 0 16px;
			gap: 1rem;
		}

		&-title{
			line-height: 1;
			&-name{
				font-size: 17px;
				font-weight: 500;
				line-height: 22px;
				color: var(--chat-header-color-name);
			}
			&-info{
				font-size: 13px;
				line-height: 18px;
				color: var(--chat-header-color-info);
			}
		}

		&-controlls{
			margin-left: auto;
		}
	}

	&-list{
		background: var(--chat-content-bg-color);
		flex: 1;
		overflow-y: auto;
		margin-right: 1px;
		margin-top: 65px;
		-webkit-overflow-scrolling: touch;
		position: relative;
		&-items{
			padding: 0 5px 5px;
		}

	}

	&-message{

		&-date{
			display: block;
			margin-bottom: 10px;
			text-align: center;
			span{
				max-width: 150px;
				font-weight: 500;
				text-transform: uppercase;
				color: var(--chat-message-color-date);
				background-color: var(--chat-message-bg-color-date);
				border-radius: 4px;
				text-align: center;
				margin: 10px auto;
				font-size: 12px;
				padding: 4px;
				display: block;
				overflow-wrap: break-word;
				position: relative;
				white-space: normal;
				box-shadow: 0 1px 1px -1px #0000001a, 0 1px 1px -1px #0000001c, 0 1px 2px -1px #0000001c;
			}
		}
		&-box{
			display: flex;
			flex: 0 0 50%;
			max-width: 50%;
			justify-content: flex-start;
			line-height: 1.4;
			&-current{
				margin-left: 50%;
				justify-content: flex-end;
			}
		}
		&-container{
			position: relative;
			padding: 2px 10px;
			align-items: end;
			min-width: 100px;
			box-sizing: content-box;
		}
	}



	&-message-card{
		background-color: var(--chat-message-bg-color);
		color: var(--chat-message-color);
		border-radius: 8px;
		font-size: 14px;
		padding: 6px 9px 3px;
		white-space: pre-line;
		max-width: 100%;
		-webkit-transition-property: box-shadow, opacity;
		transition-property: box-shadow, opacity;
		transition: box-shadow .28s cubic-bezier(.4,0,.2,1);
		will-change: box-shadow;
		box-shadow: 0 1px 1px -1px #0000001a, 0 1px 1px -1px #0000001c, 0 1px 2px -1px #0000001c;
		&.--my{
			background-color: var(--chat-message-bg-color-me) !important;
		}
		&.--is-system{
			background-color: #e7effc;
		}
		&-text{
			margin: 0;
		}
		&-timestamp{
			font-size: 10px;
			color: var(--chat-message-color-timestamp);
			text-align: right;
		}

		&-author{
			font-size: 10px;
			font-weight: 600;
			color: #1980d7;
			span{

			}
		}
	}

	&-footer{
		width: 100%;
		border-bottom-right-radius: 4px;
		z-index: 10;
		&-container{
			display: flex;
			position: relative;
			background: var(--chat-footer-bg-color);
			padding: 10px 8px;
		}
		&-textarea{
			max-height: 300px;
			overflow-y: auto;
			height: 20px;
			width: 100%;
			line-height: 20px;
			outline: 0;
			resize: none;
			border-radius: 20px;
			padding: 12px 16px;
			box-sizing: content-box;
			font-size: 16px;
			background: var(--chat-bg-color-input);
			color: var(--chat-color);
			caret-color: var(--chat-color-caret);
			border: var(--chat-border-style-input);
			max-height: 120px;
			-webkit-appearance: none;
		}
		&-controlls{
			display: flex;
			align-items: center;
			margin-left: 5px;
			&-btn{
				max-height: 30px;
				display: flex;
				cursor: pointer;
				transition: all .2s;
			}
		}
	}
}
</style>